((document, window) => {
	// Tolerance (px) is the size a viewport can chance
	// before updating css variables this prevents jerky
	// resizing when browser chrome disappears on mobile
	const tolerance = 60;

	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${ vh }px`);
	let lastHeight = window.innerHeight;

	let vw = window.innerWidth * 0.01;
	document.documentElement.style.setProperty('--vw', `${ vw }px`);
	let lastWidth = window.innerWidth;

	if (document.querySelector('.site-header')) {
		const headerHeight = document.querySelector('.site-header');
		let offsetHeight = 0;

		if (headerHeight) {
			offsetHeight = headerHeight.offsetHeight;
		} else {
			offsetHeight = 100;
		}
		document.documentElement.style.setProperty('--header-height', `${ offsetHeight }px`);
	}

	const resizeVars = () => {
		if (Math.abs(lastHeight - window.innerHeight) > tolerance) {
			vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${ vh }px`);
			lastHeight = window.innerHeight;
		}

		if (lastWidth !== window.innerWidth) {
			vw = window.innerWidth * 0.01;
			document.documentElement.style.setProperty('--vw', `${ vw }px`);
			lastWidth = window.innerWidth;
		}

		if (document.querySelector('.site-header')) {
			const headerHeight = document.querySelector('.site-header');
			let offsetHeight = 0;

			if (headerHeight) {
				offsetHeight = headerHeight.offsetHeight;
			} else {
				offsetHeight = 100;
			}
			document.documentElement.style.setProperty('--header-height', `${ offsetHeight }px`);
		}
	};
	window.addEventListener('resize', () => {
		resizeVars();
	});
	setTimeout(function() {
		resizeVars();
	}, 100);
	window.addEventListener('load', () => {
		resizeVars();
	});
})(document, window);
